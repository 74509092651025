<template>
  <vue-good-table :columns="columns" :rows="rows" :sort-options="sortOptions" :search-options="searchOptions" :pagination-options="paginationOptions">
    <template #table-row="props">
      <span v-if="props.column.type == 'defaultDate'">
        <span>{{ new Date(props.row[props.column.field]).toLocaleString() }}</span>
      </span>
      <span v-else-if="props.column.type == 'actions'">
        <slot name="actions" :item="props.row"></slot>
      </span>
      <span v-else-if="props.column.type == 'preview'">
        <slot name="preview" :item="props.row"></slot>
      </span>
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
  </vue-good-table>
</template>

<script>
import { VueGoodTable } from "vue-good-table-next";

export default {
  name: "DefaultTable",
  props: {
    columns: {
      type: Array,
      required: true
    },
    rows: {
      type: Array,
      required: true
    },
    initialSortBy: {
      type: Object,
      default: undefined
    },
    searchable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    sortOptions() {
      return {
        initialSortBy: this.initialSortBy
      };
    },
    searchOptions() {
      if (!this.searchable) return undefined;

      return {
        enabled: true,
        placeholder: "Liste durchsuchen..."
      };
    },
    paginationOptions() {
      return {
        enabled: this.rows?.length > 9,
        nextLabel: "Weiter",
        prevLabel: "Zurück",
        ofLabel: "von",
        allLabel: "Alle",
        rowsPerPageLabel: "Zeilen pro Seite"
      };
    }
  },
  components: {
    VueGoodTable
  }
};
</script>

<style module lang="scss">

</style>
