<template>
  <div class="flex-row flex-center flex-hcenter" :class="$style.spinner">
    <div class="preloader-wrapper big active">
      <div class="spinner-layer spinner-green-only">
        <div class="circle-clipper left">
          <div class="circle"></div>
        </div>
        <div class="gap-patch">
          <div class="circle"></div>
        </div>
        <div class="circle-clipper right">
          <div class="circle"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Spinner"
};
</script>

<style module lang="scss">
  .spinner {
    height: 50vh;
  }
</style>
